.accordion {
  color: var(--text);

  .accordion__item {
    padding-left: 16px;
    margin-bottom: 16px;

    &.active {
      padding-left: 0;

      .accordion__item-content {
        max-height: 380px;
        opacity: 1;
        overflow: visible;
      }
      .accordion__item-title {
        padding-left: 1.5rem;
        svg {
          transform: rotate(90deg);
        }
      }
    }

    &.hidden {
      .accordion__item-content,
      .accordion__item-title {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
      }
    }

    .accordion__item-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;

      svg {
        margin-right: 8px;
        height: 12px;
        transition: transform 0.5s;

        path {
          fill: $grey;
        }
      }
    }

    .accordion__item-content {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 1s;
      transition-property: max-height, opacity;
    }
  }
}
