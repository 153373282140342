@mixin display($display) {
  @if $display == flex {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
  } @else if $display == grid {
      display: -ms-grid;
      display: grid;
  } @else if $display == inline {
      display: inline;
  } @else if $display == inline-block {
      display: inline-block;
  } @else if $display == block {
      display: block;
  } @else if $display == none {
      display: none;
  }
}

@mixin justify-content($justify) {
  -webkit-box-pack:$justify;
  -ms-flex-pack:$justify;
  justify-content: $justify;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
   -ms-align-items: $align;
    -ms-flex-align: $align;
       align-items: $align;
}

@mixin align-self($align) {
  @if $align == flex-start {
      -ms-flex-item-align: start;
      align-self: flex-start;
  }
}

@mixin flex-direction($direction) {
@if $direction == row {
  -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
} @else if $direction == row-reverse {  
  -webkit-box-direction: reverse;
      -moz-box-direction: reverse;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
} @else if $direction == column {
  -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
} @else if $direction == column-reverse {
  -webkit-box-direction: reverse;
      -moz-box-direction: reverse;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
}
  
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
  
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}



@mixin box-shadow($content) {
  -webkit-box-shadow: $content;
          box-shadow: $content;    
}

@mixin rotate($degree) {
  -webkit-transform: rotate($degree);
      -ms-transform: rotate($degree);
          transform: rotate($degree);
}