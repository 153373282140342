.theme-switcher {
  display: flex;
  padding: 2px;
  border-radius: 50px;
  background: var(--white);
  position: relative;
  width: 48px;
  height: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid $grey;

  svg {
    margin: 4px;
    width: 12px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 16px;
    height: 16px;
    margin: auto;
    border-radius: 50%;
    background-color: var(--menu-card);
    transition: all 0.3s ease-in-out;
    transform: translateX(0px);
    transition-property: transform, background-color;
  }

  &.dark {
    &::after {
      transform: translateX(12px);
    }
  }

  &.light {
    &::after {
      transform: translateX(-12px);
    }
  }

  &.size-2 {
    width: 96px;
    height: 40px;

    svg {
      width: 24px;
    }

    &::after {
      content: "";
      width: 32px;
      height: 32px;
    }

    &.dark {
      &::after {
        transform: translateX(24px);
      }
    }

    &.light {
      &::after {
        transform: translateX(-24px);
      }
    }
  }
}
