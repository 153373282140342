.mt8 {
  margin-top: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mt24 {
  margin-top: 24px;
}

.mt48 {
  margin-top: 48px;
}

.mt40 {
  margin-top: 40px;
}

.pt8 {
  padding-top: 8px;
}

.pt48 {
  padding-top: 48px;
}

.pb8 {
  padding-bottom: 8px;
}
