.steps {
  @include display(flex);
  @include flex-direction(column);
  max-width: 16.1875rem;
  margin: 0 auto;
  padding: 3.75rem 0 0;
  
  .step {
    @include display(flex);
    flex-direction: column;
    @include align-items(center);

    .step__icon {
      width: 5rem; height: 5.25rem;
      
      img,
      svg {
        width: 100%;
      }
    }

    .step__title {
      margin-top: 0.625rem;
      margin-bottom: 0.3125rem;
      font-style: normal;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.4375rem;
      color: $primary;      
    }

    .step__text {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      text-align: center;
      line-height: 1.1875rem;
      max-width: 11.3125rem;
    }
  }

  .step:not(:last-child) {
    margin-bottom: 3.8125rem;
  }
}