.points-message {
  position: absolute;
  user-select: none;
  pointer-events: none;
  z-index: 1000;
  font-weight: bold;
  font-size: 50px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  font-family: $font-primary;
  opacity: 0;
  transition: all 2s cubic-bezier(0.46, 0.46, 0, 1.04);
  transition-property: opacity, bottom;
  bottom: 100px;

  &.active {
    opacity: 1;
    bottom: 150px;
  }

  &.leave {
    opacity: 0;
    bottom: 200px;
  }

  &.isIncreasing {
    color: $success;
  }

  &.isDecreasing {
    color: $danger;
  }
}
