.bible-reading {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__head {
    display: flex;
    justify-content: space-between;
    position: absolute;
    background: var(--modal-background);
    width: 100%;
    z-index: 1;
    top: 0;

    .bible-reading__title {
      height: 74px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      transition: height 0.2s;
      flex-wrap: wrap;
      width: 100%;
      color: var(--modal-title);

      h2 {
        font-weight: bold;
        font-size: 22px;
        font-family: $font-secondary;
        transition: all 0.2s;
        transition-property: font-size;
        text-align: left;
        margin-right: 19px;
      }
    }

    .bible-reading__close {
      padding: 24px 16px 24px 16px;
      width: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;

      path {
        fill: var(--modal-title);
      }
    }

    &.focusMode {
      .bible-reading__title {
        height: 35px;

        h2 {
          font-size: 16px;
        }
      }

      .bible-reading__versions-selector,
      .bible-reading__close {
        display: none;
      }
    }
  }

  .bible-chapters {
    padding-bottom: 40px;
  }

  &__body {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    cursor: auto;
    padding: 0 8px 16px 8px;
    margin: 0 8px;
    transition: box-shadow 0.2s ease-in-out;
    scroll-behavior: smooth;
    padding-top: 74px;

    &::before {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      box-shadow: inset 0 8px 5px -5px rgba(0, 0, 0, 0.2);
      top: 35px;
      left: 0;
      transition: top 0.2s ease-in-out;
      pointer-events: none;
      user-select: none;
    }

    &.isScrollingUp {
      &::before {
        top: 72px;
      }
    }

    &.isOnScrollTop {
      &::before {
        box-shadow: none;
      }
    }

    &.isLoading {
      overflow-y: hidden;
    }
  }

  &__versions-selector {
    display: flex;
    justify-content: center;
    padding-top: 0;
    width: auto;

    @media (max-width: 358px) {
      width: 100%;
    }

    &.isBeforeText {
      .select {
        justify-content: flex-start;

        .select__items {
          height: 430px;
          width: 320px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          position: fixed;
          top: 73px;
          left: 0;
        }
      }
    }

    .select {
      justify-content: start;

      .select__control {
        box-shadow: none;
        display: flex;

        .select__control-button {
          padding: 0;
          background-color: transparent;
          box-shadow: none;

          .select__control-button-text {
            color: inherit;
          }
        }

        .select__control-button-icon {
          margin-left: 12px;

          svg {
            width: 14px;

            path {
              fill: var(--modal-title);
            }
          }
        }
      }
    }

    .pipe {
      display: inline-block;
      transform: translateY(-2px);
      margin-left: -3px;
      margin-right: 8px;

      @media (max-width: 358px) {
        display: none;
      }
    }

    .select__control {
      width: 100%;
      max-width: 200px;
    }
  }

  &__read-control {
    position: relative;
  }

  &__controls {
    position: absolute;
    bottom: 32px;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    pointer-events: none !important;
    transition: bottom 0.2s ease-in-out;

    &.isOnScrollBottom {
      bottom: 45px;
    }

    &.isOnLastPage.isOnScrollBottom {
      bottom: 131px;
    }

    &.isOnLastPage.noCopyright.isOnScrollBottom {
      bottom: 65px;
    }

    &__button {
      &.left,
      &.right {
        width: 80px;
        height: 80px;
        align-items: center;
        display: flex;
      }

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }

      span {
        background: var(--input);
        box-shadow: var(--card-box-shadow);
        border-radius: 50px;
        border: none;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;

        path {
          fill: var(--modal-title);
        }
      }
      &:active span {
        transform: scale(0.95);
      }
    }
  }

  &__finish-reading {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }

  &__version {
    display: flex;
    justify-content: center;
    display: flex;
    font-size: 12px;
  }

  &__copyright {
    text-align: center;
    font-size: 12px;
    padding-top: 32px;
  }
}
