.add-participants {
  width: 100%;
  text-align: left;

  .button--medium {
    margin-left: auto;
    min-width: 124px;
    width: 100%;
    max-width: calc(53% - 24px);

    @include desktop {
      margin-right: auto;
      max-width: 182.5px;
      margin-left: 0;
    }
  }

  .add-participants__link {
    margin-bottom: 16px;
    word-break: break-all;
    user-select: all;
  }

  .emails-box {
    margin-bottom: 8px;
  }

  .add-participants__title {
    font-family: $primary;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  .add-participants__section:not(:last-child) {
    margin-bottom: 24px;
  }

  .add-participants__title {
    margin-bottom: 16px;
  }

  .add-participants__share-link {
    display: flex;

    .add-participants__share-link-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border: 1px solid #828282;
      text-align: center;
      border-radius: 8px;
      width: 100%;
      cursor: pointer;
      max-width: 50%;

      line,
      path {
        stroke: var(--text);
      }

      circle {
        fill: var(--text);
      }

      &:not(:last-child) {
        margin-right: 24px;
      }

      svg {
        margin-bottom: 8px;
      }
      span {
      }
    }
  }
}
