.plan-checklist {
  width: 100%;
  max-width: 366px;
  margin: 0 auto;

  .plan-checklist__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .arrow {
      width: 80px;
      height: 28px;
      background-size: 10px;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: all 0.4s;

      @extend .noClickHighlight;

      &:active {
        transform: scale(0.95);
      }

      &.arrow-left path,
      &.arrow-right path {
        fill: var(--main-card-title);
      }

      &.arrow-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &.arrow-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .plan-checklist__header-title {
      color: var(--main-card-title);
      font-size: 22px;
    }
    .plan-checklist__header-subtitle {
      color: var(--main-card-subtitle);
      font-size: 11px;
      text-align: center;
      width: 100%;
      display: inherit;
    }
  }
  .plan-checklist__body {
    .checklist {
      position: relative;
      scroll-behavior: smooth;
      padding-top: 16px;
      overflow: scroll;

      &.isScrolling {
        box-shadow: inset 0 8px 5px -9px rgba(0, 0, 0, 0.2);
      }
    }

    .checklist::-webkit-scrollbar {
      display: none;
    }
  }
}
