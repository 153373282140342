.modal-bottom {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  .modal-bottom__window {
    transition: transform 0.3s ease-in-out;
    background: var(--main-card);
    box-shadow: var(--main-card-box-shadow);
    color: var(--text);
    width: 100%;
    padding: 32px 0;
    position: relative;
    z-index: 10;
    overflow-y: scroll;
    max-height: 100%;
    transform: translateY(100%);

    &.active {
      transform: translateY(0);
    }
  }
}
