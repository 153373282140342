.podium {
  display: flex;
  justify-content: center;
  position: relative;
  animation-name: fadeUp;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  overflow: hidden;
  max-height: 200px;
  align-items: flex-start;
  margin-bottom: -10px;

  &.podiumIsLoaded {
    .podium-column {
      &.silver,
      &.gold,
      &.bronze,
      &.silver .podium-column__header::before,
      &.gold .podium-column__header::before,
      &.bronze .podium-column__header::before {
        animation-duration: 0s;
        animation-delay: 0s;
      }
    }
  }

  &.isNativeIOS {
    .podium-column {
      .podium-column__header {
        animation-name: fade;
        animation-duration: 0.5s;
        opacity: 0;
        animation-fill-mode: forwards;
        &::before {
          animation-name: fadeUp;
          background: none;
        }
      }
      &.silver .podium-column__header {
        animation-delay: 1.5s;
      }
      &.bronze .podium-column__header {
        animation-delay: 0.5s;
      }
      &.gold .podium-column__header {
        animation-delay: 2.5s;
      }
    }
  }

  &.onlyPodium {
    margin-top: 140px;
  }

  .podium__inside {
    display: flex;
    align-items: flex-end;
  }
  .podium-column {
    display: flex;
    flex-direction: column;
    width: 92px;

    &__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .podium-column__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--white);
      font-family: $font-primary;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 4px;
      overflow: hidden;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 200%;
        width: 100%;
        background: transparent;
        background: linear-gradient(
          to top,
          transparent 0%,
          var(--app-background) 50%,
          var(--app-background) 100%
        );
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        left: 0;
        transform: translateY(20%);
        animation-name: bottomUp;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }

      .podium__pic {
        border-radius: 50%;
        padding: 4px;
        height: 56px;
        width: 56px;
        margin-bottom: 4px;

        span {
          height: 100%;
          width: 100%;
          display: block;
          margin-bottom: 4px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          background-color: var(--white);
          background-repeat: no-repeat;
          background-size: contain;
          box-shadow: var(--input-box-shadow);
          background-position: center;
          max-width: 100%;
        }

        &.no-pic span {
          background-repeat: no-repeat;
          background-size: 29px;
        }
      }
    }

    &.silver,
    &.gold,
    &.bronze {
      opacity: 0;
    }

    &.silver {
      transform: translateY(30px);
      animation-name: silver;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      animation-delay: 1s;

      .podium-column__header::before {
        animation-delay: 1.5s;
      }

      .podium-column__metal {
        border-radius: 8px 0 0 0;

        .podium-column__metal-content {
          height: 60px;
        }
      }
      .podium__pic {
        background: linear-gradient(
            49.71deg,
            rgba(0, 0, 0, 0.255) 43.08%,
            rgba(0, 0, 0, 0) 43.08%
          ),
          #cacacf;
      }
      .podium-column__metal {
        background: linear-gradient(
            55.23deg,
            rgba(0, 0, 0, 0.068) 49.87%,
            rgba(0, 0, 0, 0) 49.88%
          ),
          #cacacf;
      }
    }
    &.gold {
      animation-name: gold;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      animation-delay: 2s;

      .podium-column__header::before {
        animation-delay: 2.5s;
      }

      .podium-column__metal {
        border-radius: 8px 8px 0 0;

        .podium-column__metal-content {
          height: 93px;
        }
      }
      .podium__pic {
        background: linear-gradient(
            43.48deg,
            rgba(0, 0, 0, 0.272) 49.89%,
            rgba(0, 0, 0, 0) 49.89%
          ),
          #ffd338;
      }
      .podium-column__metal {
        background: linear-gradient(
            55.23deg,
            rgba(0, 0, 0, 0.068) 49.87%,
            rgba(0, 0, 0, 0) 49.88%
          ),
          #ffd338;
      }
    }
    &.bronze {
      transform: translateY(49px);
      animation-name: bronze;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;

      .podium-column__header::before {
        animation-delay: 0.5s;
      }

      .podium-column__metal {
        border-radius: 0 8px 0 0;

        .podium-column__metal-content {
          height: 44px;
        }
      }
      .podium__pic {
        background: linear-gradient(
            55.5deg,
            rgba(0, 0, 0, 0.343) 43.48%,
            rgba(0, 0, 0, 0) 43.48%
          ),
          #f49f3f;
      }
      .podium-column__metal {
        background: linear-gradient(
            55.23deg,
            rgba(0, 0, 0, 0.068) 49.87%,
            rgba(0, 0, 0, 0) 49.88%
          ),
          #f49f3f;
      }
    }
    .podium-column__metal {
      border-radius: 8px;
      height: 130px;
      width: 92px;
      padding: 4px 0;

      .podium-column__metal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }
    .podium-column__points {
    }
    .podium-column__position {
      font-weight: bold;
    }
  }
}

@keyframes bronze {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    transform: translateY(49px);
    opacity: 1;
  }
}

@keyframes gold {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes silver {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    transform: translateY(33px);
    opacity: 1;
  }
}

@keyframes bottomUp {
  from {
    transform: translateY(20%);
  }
  to {
    transform: translateY(-100%);
  }
}
