.section {
  padding: 3.75rem 0;

  .section__title {
    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0.6875rem;
    }
  }
  
  .section__title,
  .section__subtitle {
    max-width: 15.75rem;
    margin: 0 auto;
  }

  .section__subtitle {
    margin-bottom: 1.5rem;
  }
}