.progress-bar {
  margin-top: 16px;

  .progress-bar__label {
    color: var(--white);
    margin-bottom: 6px;
  }
  .progress-bar__bar {
    height: 8px;
    position: relative;
    background-color: var(--white);
    border-radius: 8px;
    overflow: hidden;

    .progress-bar__bar-fill {
      height: 8px;
      position: absolute;
      transition: all 0.1s ease;
      transition-property: width, background-color;
      width: 0%;
      top: 0;
      left: 0;
      background-color: $success-dark;
      border-radius: 8px;
    }
  }

  .progress-bar__dates {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    .progress-bar__date-start {
    }
    .progress-bar__date-start {
    }
  }
}
