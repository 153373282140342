.navbar {
  .navbar__logo {
    line {
      stroke: var(--logo-dark-bg);
    }

    rect,
    path {
      fill: var(--logo-dark-bg);
    }
  }
  .navbar__inside {
    max-width: 397px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin: 0 auto;
    color: var(--white);
    font-size: 18px;

    .navbar__back,
    .navbar__right {
      width: 80px;
    }

    .navbar__back {
      display: flex;
      align-items: center;
    }

    .navbar__title {
      text-align: center;
    }

    .arrow {
      cursor: pointer;
    }
  }
}
