.single-payment-form {
  .single-payment-form__message {
    margin-top: 16px;
  }
}

.subscription-payment-form {
  .subscription-payment-form__message {
    margin-top: 16px;
  }

  .subscription-payment-form__element {
    height: 0;
    overflow: hidden;
    background-color: var(--input);
  }

  .subscription-payment-form__element.isMounted {
    overflow: auto;
    height: 44px;
    padding: 13px 8px 0;
    border-radius: 8px;
    box-shadow: var(--input-box-shadow);
    border: 1px solid var(--input-border);
  }
}
