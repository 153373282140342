.view-question {
  .view-question__title {
    width: 100%;
    font-size: 18px;

    textarea {
      resize: none;
    }
  }
  .view-question__alternatives-title {
    padding-top: 16px;
    width: 100%;
  }
  .view-question__alternatives {
    padding-top: 16px;
    width: 100%;
  }
  .view-question__alternative {
    margin-bottom: 16px;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px 8px 8px 30px;
    border-radius: 8px;
    background-color: var(--input);
    box-shadow: var(--card-box-shadow);

    &.notOwner {
      cursor: pointer;
    }

    &.isSelected,
    &.isCorrect,
    &.active {
      box-shadow: 0px 0px 4px #009b72;

      &::before {
        border: 2px solid $success;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 12px;
        border-radius: 50%;
        background: $success;
        height: 8px;
        width: 8px;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }

    &.isSelected {
      box-shadow: 0px 0px 4px var(--main-card-title);

      &::before {
        border: 2px solid var(--main-card-title);
      }

      &::after {
        background: var(--main-card-title);
      }

      &.isCorrect {
        box-shadow: 0px 0px 4px $success;

        &::before {
          border: 2px solid $success;
        }

        &::after {
          background: $success;
        }
      }

      &.isWrong {
        box-shadow: 0px 0px 4px $danger;

        &::before {
          border: 2px solid $danger;
        }

        &::after {
          background: $danger;
        }
      }
    }

    &.isSelectingAnswer {
      cursor: pointer;

      input {
        border: none;
        padding: 8px;
        cursor: pointer;
        user-select: none;
        pointer-events: none;
      }
    }

    svg {
      margin-left: 12px;
      cursor: pointer;

      path {
        fill: $grey;
      }
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 8px;
      border-radius: 50%;
      border: 1px solid var(--input-text);
      height: 16px;
      width: 16px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }

  .view-question__edit-question {
    display: flex;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    padding-top: 8px;

    svg {
      margin-right: 8px;
    }
  }

  .view-question__alternatives-add {
    margin: 8px 0;
    cursor: pointer;

    button {
      background: transparent;
      border: none;
      color: $grey;
      padding-left: 42px;
      position: relative;
      cursor: text;
      padding-left: 31px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        border-radius: 50%;
        border: 1px solid;
      }
    }
  }
  .view-question__alternative-input {
    width: 100%;
  }
  .view-question__selecting-alternative {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 16px;
  }
  .view-question__select-alternative {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 16px 0;
    color: $primary;
    cursor: pointer;

    &.error {
      color: $danger;
      animation-name: shake;
      animation-duration: 0.4s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-direction: alternate;

      svg {
        path {
          fill: $danger;
        }
      }
    }

    svg {
      margin-right: 16px;

      path {
        fill: $primary;
      }
    }
  }
  .view-question__points {
    padding-top: 16px;
    width: 100%;

    p {
      margin-bottom: 16px;
    }
  }
  .view-question__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    align-items: center;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
