.offline-versions-state {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: auto;

  .offline-versions-state__text {
    h4 {
      font-family: $font-primary;
      font-size: 16px;
      margin-bottom: 4px;
      font-weight: bold;
    }
  }
}

.select .select__items .select__item .offline-versions-state svg {
  width: 48px;
  margin-left: 17px;
}
