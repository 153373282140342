.add-question {
  .add-question__title {
    width: 100%;

    textarea {
      resize: none;
    }
  }
  .add-question__alternatives-title {
    padding-top: 16px;
    width: 100%;
  }
  .add-question__alternatives {
    padding-top: 16px;
    width: 100%;
  }
  .add-question__alternative {
    margin-bottom: 16px;
    padding-left: 24px;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;

    &.active {
      &::before {
        border: 1px solid $success;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 4px;
        border-radius: 50%;
        background: $success;
        height: 8px;
        width: 8px;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }

    &.isSelectingAnswer {
      cursor: pointer;
      margin-bottom: 24px;

      input {
        border: none;
        padding: 8px;
        cursor: pointer;
        user-select: none;
        pointer-events: none;
      }
    }

    svg {
      margin-left: 12px;
      cursor: pointer;

      path {
        fill: var(--main-card-subtitle);
      }
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      border-radius: 50%;
      border: 1px solid var(--input-text);
      height: 16px;
      width: 16px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
  .add-question__alternatives-add {
    margin: 8px 0;
    cursor: pointer;

    button {
      background: transparent;
      border: none;
      color: var(--main-card-subtitle);
      padding-left: 42px;
      position: relative;
      cursor: text;
      padding-left: 31px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        border-radius: 50%;
        border: 1px solid;
      }
    }
  }
  .add-question__alternative-input {
    width: 100%;
  }
  .add-question__selecting-alternative {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 16px;
  }
  .add-question__select-alternative {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 16px 0;
    color: var(--main-card-title);
    cursor: pointer;

    &.error {
      color: $danger;
      animation-name: shake;
      animation-duration: 0.4s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-direction: alternate;

      svg {
        path {
          fill: $danger;
        }
      }
    }

    svg {
      margin-right: 16px;

      path {
        fill: var(--main-card-title);
      }
    }
  }
  .add-question__points {
    padding-top: 16px;
    width: 100%;

    p {
      margin-bottom: 16px;
    }
  }
  .add-question__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    align-items: center;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.add-question__controls-delete {
  text-decoration: underline;
  color: $danger;
  cursor: pointer;
  font-weight: bold;
}
