.questions {
  padding: 1px;

  .question {
    background: var(--input);
    box-shadow: var(--card-box-shadow);
    border-radius: 8px;
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .question__feedback {
    color: $success;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .question__checked-points {
    display: block;
  }

  .question__checked {
    border-radius: 50%;
    padding: 4px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;

    &.success {
      border: 2px solid $success;
      color: $success;
    }

    &.error {
      border: 2px solid $danger;
      color: $danger;
    }
  }

  .questions__add {
    display: flex;
    justify-content: center;
    padding-top: 24px;
  }
}
