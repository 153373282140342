.content {
    margin: 0 auto;
    max-width: 16.1875rem;
    
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;
    }
}