.no-questions {
  text-align: center;

  h3 {
    margin-top: 16px;
    font-weight: bold;
    color: var(--main-card-title);
    font-size: 24px;
  }
}
