.new-challenge {
  .new-challenge__form {
    .new-challenge__title {
      font-family: $font-primary;
      font-weight: bold;
      font-size: 22.78px;
      line-height: 27px;
      color: var(--white);
      margin-bottom: 24px;
      width: 100%;
    }
    .new-challenge__name-input {
    }

    .input-error {
      color: $white;
      font-size: 12px;
    }

    .new-challenge__dates {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .input-wrap {
        text-align: center;

        label {
          color: $white;
          margin-bottom: 8px;
          display: block;
          font-size: 14px;
        }

        > div > div > div {
          background-color: rgba(0, 0, 0, 0) !important;
        }
      }
    }
    .new-challenge__controls {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      button {
        margin-left: auto;
      }
    }
  }
}

.new-challenge__controls-jump {
  color: var(--text);
  text-decoration: underline;
  cursor: pointer;
}

.new-challenge-stepper {
  padding-top: 80px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  width: 127px;
  margin-left: auto;
  margin-right: auto;

  .new-challenge-stepper__step {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--white);
    transition: background-color 0.3s ease-in-out;
    position: relative;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 40px;
      height: 4px;
      left: -40px;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;
    }

    &::before {
      background: $background;
    }

    &::after {
      transition: width 0.3s ease-in-out;
      background: inherit;
      width: 0;
    }

    &:nth-child(1) {
      &::after,
      &::before {
        content: none;
      }
    }

    &.active {
      &::after {
        width: 40px;
      }
      background-color: $success;
    }
  }
}
