.header {
  background-color: $primary;
  padding: 3.9375rem 0 8.5625rem 0;
  animation-name: fade;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  .header__title-wrap {
    padding-left: 0.45625rem;
    max-width: 15rem;
    margin: 0 auto;
  }

  .header__title {
    max-width: 14.375rem;
    color: $white;
    font-style: normal;
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 2.1875rem;
    letter-spacing: 0.0296625rem;
    margin-bottom: 0.6875rem;
  }

  .header__subtitle {
    color: $white;
    font-style: normal;
    font-weight: normal;
    font-size: 1.375rem;
    line-height: 1.875rem;
    width: 10rem;
  }

  .header__buttons {
    padding: 4.9375rem 0.5rem 0;
  }
}
