.checklist {
  .checklist__item {
    display: flex;
    align-items: stretch;
    margin-bottom: 1.5rem;
    cursor: pointer;
    scroll-margin-top: 12.5rem;

    .checklist__item-content {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .read-now-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 26px;
    }

    &__read-now {
      margin-left: auto;
      font-size: 0.75rem;
      background: transparent;
      border-radius: 3.125rem;
      color: var(--text);
      padding: 0;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 0.5rem;
        // margin-right: 0.5rem;

        path {
          fill: var(--checklist-read-button);
        }
      }
    }

    i.checkbox {
      display: block;
      width: 1rem;
      height: 1rem;
      margin-right: 1.5rem;
      min-height: 1rem;
      min-width: 1rem;

      path {
        fill: var(--checklist-icon);
      }
    }
    article {
      p {
        color: var(--checklist-text);
        font-size: 1.125rem;
        margin-bottom: -0.1875rem;
        padding-right: 0.5rem;
      }
      span {
        font-size: 0.75rem;
        color: var(--checklist-date);
      }
    }
    &.checked {
      i.checkbox {
        path {
          fill: var(--checklist-icon-checked);
        }
      }
      article {
        p {
          color: var(--checklist-text-checked);
        }
        span {
          color: var(--checklist-date-checked);
        }
      }
    }
  }
}
