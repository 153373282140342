.snack-bar {
  position: fixed;
  bottom: 8px;
  right: 8px;
  left: 8px;
  padding: 16px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1001;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  bottom: -18%;
  box-shadow: var(--button-box-shadow);

  &.open {
    animation-name: open;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  &.close {
    animation-name: close;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  &.error {
    background: $danger;

    color: $white;

    svg {
      path {
        fill: $white;
      }
    }
  }

  &.success {
    background: $success;

    color: $white;

    svg {
      path {
        fill: $white;
      }
    }
  }

  &.info {
    background: $info;

    color: $white;

    svg {
      path {
        fill: $white;
      }
    }
  }

  p {
    max-width: 90%;
    padding-right: 8px;
  }
}

@keyframes open {
  0% {
    bottom: -18%;
  }

  100% {
    bottom: 70px;
  }
}

@keyframes close {
  0% {
    bottom: 70px;
  }

  100% {
    bottom: -18%;
  }
}
