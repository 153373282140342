$grid-1-mobile: 1rem;
$half-grid-mobile: $grid-1-mobile / 2;
$grid-2-mobile: $grid-1-mobile * 2;
$grid-3-mobile: $grid-1-mobile * 3;
$grid-3-mobile: $grid-1-mobile * 3;
$grid-4-mobile: $grid-1-mobile * 4;
$grid-5-mobile: $grid-1-mobile * 5;
$grid-6-mobile: $grid-1-mobile * 6;
$grid-7-mobile: $grid-1-mobile * 7;
$grid-8-mobile: $grid-1-mobile * 8;
$grid-9-mobile: $grid-1-mobile * 9;
$grid-10-mobile: $grid-1-mobile * 10;
$grid-11-mobile: $grid-1-mobile * 11;
$grid-12-mobile: $grid-1-mobile * 12;
$grid-13-mobile: $grid-1-mobile * 13;
$grid-14-mobile: $grid-1-mobile * 14;
$grid-15-mobile: $grid-1-mobile * 15;
$grid-16-mobile: $grid-1-mobile * 16;

$grid-1-tablet: 3rem;
$half-grid-tablet: $grid-1-tablet / 2;
$grid-2-tablet: $grid-1-tablet * 2;
$grid-3-tablet: $grid-1-tablet * 3;
$grid-3-tablet: $grid-1-tablet * 3;
$grid-4-tablet: $grid-1-tablet * 4;
$grid-5-tablet: $grid-1-tablet * 5;
$grid-6-tablet: $grid-1-tablet * 6;
$grid-7-tablet: $grid-1-tablet * 7;
$grid-8-tablet: $grid-1-tablet * 8;
$grid-9-tablet: $grid-1-tablet * 9;
$grid-10-tablet: $grid-1-tablet * 10;
$grid-11-tablet: $grid-1-tablet * 11;
$grid-12-tablet: $grid-1-tablet * 12;
$grid-13-tablet: $grid-1-tablet * 13;
$grid-14-tablet: $grid-1-tablet * 14;
$grid-15-tablet: $grid-1-tablet * 15;
$grid-16-tablet: $grid-1-tablet * 16;

$grid-1-desktop: 4.6875rem;
$half-grid-desktop: $grid-1-desktop / 2;
$grid-2-desktop: $grid-1-desktop * 2;
$grid-3-desktop: $grid-1-desktop * 3;
$grid-3-desktop: $grid-1-desktop * 3;
$grid-4-desktop: $grid-1-desktop * 4;
$grid-5-desktop: $grid-1-desktop * 5;
$grid-6-desktop: $grid-1-desktop * 6;
$grid-7-desktop: $grid-1-desktop * 7;
$grid-8-desktop: $grid-1-desktop * 8;
$grid-9-desktop: $grid-1-desktop * 9;
$grid-10-desktop: $grid-1-desktop * 10;
$grid-11-desktop: $grid-1-desktop * 11;
$grid-12-desktop: $grid-1-desktop * 12;
$grid-13-desktop: $grid-1-desktop * 13;
$grid-14-desktop: $grid-1-desktop * 14;
$grid-15-desktop: $grid-1-desktop * 15;
$grid-16-desktop: $grid-1-desktop * 16;
