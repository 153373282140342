.select {
  position: relative;
  cursor: pointer;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: center;

  &.active {
    .select__items {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;

      * {
        pointer-events: all;
      }
    }

    .select__control .select__control-button-icon {
      transform: rotate(-180deg);
    }
  }

  .select__items {
    position: absolute;
    z-index: 1;
    width: 100%;
    background: var(--input);
    color: var(--input-text);
    border-radius: 8px;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.5s;
    transition-property: opacity, transform;
    pointer-events: none;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    bottom: 120%;
    max-width: 310px;
    width: 100%;
    max-height: 430px;
    overflow-y: scroll;
    padding: 8px 4px;
    overscroll-behavior: contain;

    * {
      pointer-events: none;
    }

    .select__item-title {
      font-weight: bold;
    }

    .select__item {
      .select__item-label {
        padding: 8px 16px;
      }

      .select__item-icon {
        padding: 8px 16px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .select__item-title {
      padding: 8px 16px;
      border-radius: 8px;
    }

    .select__item {
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg,
      img {
        width: 16px;
      }

      .download path {
        stroke: var(--text);
      }
    }

    .select__item.active {
      background: $grey-dark;
      color: $white;

      .download path {
        stroke: var(--white);
      }

      .check path {
        fill: $white;
      }
    }
  }
  .select__control {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    &.noStyle {
      background: transparent;
      box-shadow: none;

      .select__control-button {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
      }

      .select__control-button-icon {
        display: none;
      }
    }

    .select__control-button {
      background: var(--input);
      box-shadow: var(--input-box-shadow);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 24px;
    }
    .select__control-button-text {
      color: var(--input-text);
    }
    .select__control-button-icon {
      transform: rotate(0deg);
      transition: transform 0.5s;

      path {
        fill: var(--input-text);
      }
    }
  }
}
