.failed-to-load-chapter {
  padding-top: 32px;
  text-align: left;
  color: var(--text);

  svg {
    max-width: 300px;
    display: block;
    margin: 32px auto;
  }

  h3.failed-to-load-chapter__title-3,
  h4.failed-to-load-chapter__title-4,
  .failed-to-load-chapter__ul,
  .failed-to-load-chapter__li,
  .failed-to-load-chapter__li,
  .failed-to-load-chapter__p {
    margin-bottom: 8px;
  }

  h3.failed-to-load-chapter__title-3 {
    text-align: left;
    font-size: 22px;
    line-height: 1.2;
  }
  .failed-to-load-chapter__title-4 {
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: bold;
  }
  .failed-to-load-chapter__ul {
    list-style: disc;
    padding-left: 32px;
  }
  .failed-to-load-chapter__li {
  }
  .failed-to-load-chapter__li {
  }
  .failed-to-load-chapter__p {
  }
}
