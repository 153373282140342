.call-to-login {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -1px 4px -1px rgba(0, 0, 0, 0.25);
  background: $white;
  padding: 8px;

  &.isIphone {
    padding: 8px 0 32px;
  }
}
