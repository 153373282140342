.text-centered {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.font-regular {
  font-weight: 500;
}

.text-left {
  text-align: left;
}

.font-size-0 {
  font-size: 11px;
}

.font-size-1 {
  font-size: 12px;
}

.font-size-2 {
  font-size: 14px;
}

.font-size-3 {
  font-size: 16px;
}

.font-size-4 {
  font-size: 18px;
}

.font-size-5 {
  font-size: 20px;
}

.fz6 {
  font-size: 22px;
}

.fz7 {
  font-size: 24px;
}

.tdn {
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.font-primary {
  font-family: $font-primary;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--selection-color);
  background: var(--selection-bg);
}

::selection {
  color: var(--selection-color);
  background: var(--selection-bg);
}
