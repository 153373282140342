$mobile-small-up: 30rem; // == 480px
$mobile-up: 48rem; // == 768px
$desktop-up: 62rem; // == 992px
$large-up: 75rem; // == 1200px

@mixin tablet {
  @media (min-width: #{$mobile-up}) and (max-width: #{$desktop-up - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-up}) {
    @content;
  }
}
