.path-fill-white {
  path {
    fill: $white;
  }
}

.path-stroke-white {
  path {
    stroke: $white;
  }
}

@include desktop {
  .desktop--80p {
    width: 80%;
    height: auto;
  }
}
