.fade-up {
  animation-name: fadeUp;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

.fade {
  animation-name: fade;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(60px);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
