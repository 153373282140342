:root {
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom);
}

body,
html,
#root {
  user-select: none;
  scroll-behavior: smooth;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  min-height: 100vh;
  background: var(--app-background);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
}

body {
  // background-color: red !important;
  overflow: hidden scroll;

  &.isOnBottom {
    background-color: var(--bottom-bar);
  }

  &.isReading {
    background-color: var(--modal-background);
  }

  &.isOnBottom {
    transition: none;
  }
}

p,
a,
span,
input,
form,
label,
button,
ul,
li,
div,
strong,
small,
table,
tbody,
tr,
td {
  font-family: $font-secondary;
}

a {
  color: $info;
}
