.table {
  color: var(--text);

  .table__header {
    display: grid;
    grid-template-columns: 32px 273px 300px;
    gap: 0px 0px;
    grid-template-areas: "position points name";
    grid-template-columns: 10% 40% 50%;
    font-weight: bold;
    margin-bottom: 8px;
    color: #828282;
    text-transform: capitalize;

    .table__header-points {
      text-align: right;
    }
  }
  .table__body {
    overflow-y: scroll;
    padding-bottom: 24px;

    .table__middle-title {
      margin: 16px 8px 0 0;
      font-weight: bold;
    }

    .table__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      border-bottom: 0.5px solid #c4c4c4;

      .table__item-content {
        display: flex;
        grid-template-columns: 20px 40px 100%;
        grid-column-gap: 16px;
        align-items: center;
        max-width: 340px;
      }

      .table__item-action-link {
        text-decoration: underline;

        &.notInitial {
          cursor: none;
          user-select: none;
          pointer-events: none;
          text-decoration: none;
        }
      }

      &--button {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--disabled {
        color: $grey;

        .table__item-action {
          font-style: italic;
        }
      }

      .table__item-pic {
        height: 40px;
        width: 40px;
        border: 1px solid #ffffff;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        background-color: var(--white);
        background-repeat: no-repeat;
        background-size: contain;
        border: 2px solid var(--white);
        box-shadow: var(--card-box-shadow);
        background-position: center;
        background-repeat: no-repeat;

        &.no-pic {
          background-size: 19px;
        }
      }

      .table__item-position {
        color: #828282;
        text-align: left;
        width: 20px;
      }

      .table__item-name {
        font-family: $font-primary;
        font-size: 1rem;
        font-weight: bold;
        white-space: break-spaces;
        word-break: break-all;
      }
      .table__item-points {
        text-align: right;
      }
    }
  }
}

.no-plan {
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p,
  svg {
    margin-bottom: 32px;
  }
}

.invite-not-accepted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 32px;
  }

  svg {
    margin-bottom: 16px;
  }
  h3 {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 22.78px;
    line-height: 27px;
    text-align: center;
    color: var(--main-card-title);
    margin-bottom: 8px;
  }

  p {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--main-card-title);
  }
}

.table--participants {
  .table__body .table__item,
  .table__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table__item-action {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: 12px;
    cursor: pointer;

    svg {
      margin-left: 8px;

      path {
        fill: var(--text);
      }
    }
  }
}
