.language-selector {
  position: relative;
  cursor: pointer;

  .language-selector__selected {
    width: 100%;
    display: block;
  }

  .language-selector__select {
    position: absolute;
    width: 100%;
    background: var(--input);
    bottom: 30px;
    color: var(--input-text);
    border-radius: 8px;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.5s;
    transition-property: opacity, transform;
    pointer-events: none;

    * {
      pointer-events: none;
    }

    &.showSelect {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;

      * {
        pointer-events: all;
      }
    }

    .language-selector__option {
      padding: 8px;

      &:not(:last-child) {
        border-bottom: 1px solid $grey;
      }
    }
  }
}
