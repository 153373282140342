$title-1: 2rem;
$title-2: 1.75rem;
$title-3: 1.56rem;
$title-4: 1.37;
$title-5: 1.28rem;
$title-6: 1.12rem;

$subtitle-1: 2rem;
$subtitle-2: 1.75rem;
$subtitle-3: 1.56rem;
$subtitle-4: 1.37;
$subtitle-5: 1.28rem;
$subtitle-6: 1.12rem;

$content-smaller: 0.68rem;
$content-small: 0.75rem;
$content-normal: 1rem;
$content-medium: 1.12rem;
$content-large: 1.28rem;
