.bible-chapters {
  text-align: left;

  .bible-chapter {
    display: flex;
    flex-direction: column;

    &__title.active {
      margin-bottom: 26px;
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      background: #fff;
      border-radius: 50% 50% 0 0;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
    &__verses {
      font-family: $font-primary;
      text-align: left;
    }
    &__verse {
      margin-bottom: 8px;
      user-select: text;
      font-size: 1.125rem;
      font: -apple-system-body;
      line-height: 1.8;

      &__number {
        font-size: 0.7rem;
        line-height: inherit;
        margin: 0 8px;
        font: -apple-system-short-footnote;
        font-weight: bold;
        display: inline-block;
        transform: translate(0px, -2px);
      }
    }
  }
}
