.hidden {
  display: none;
}

.noClickHighlight {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.full-width {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.inline-block {
  display: inline-block;
}

.half-second-animation {
  animation-duration: 0.5s;
}
