$primary: #6a2d56;
$primary-light: #b84d94;
$primary-lighter: #f768c8;
$primary-dark: #522242;
$primary-darker: #38182d;

$secondary: #ffb540;
$secondary-light: #edb150;
$secondary-lighter: #f7ca83;
$secondary-dark: #e6a439;
$secondary-darker: #d69b3e;

$warning: #ffb540;
$warning-light: #edb150;
$warning-lighter: #f7ca83;
$warning-dark: #e6a439;
$warning-darker: #d69b3e;

$danger: #e5776b;
$danger-light: #ff6251;
$danger-lighter: #ff7566;
$danger-dark: #f74936;
$danger-darker: #ed422f;

$info: #0197f6;
$info-2: #7976f7;

// old that might work yet:
// $info-light: #9796f2;
// $info-lighter: #b8b7f7;
// $info-dark: #312faa;
// $info-darker: #14133f;

$link: #1f1cff;
$link-light: #4542f4;
$link-lighter: #5451ff;
$link-dark: #05046b;
$link-darker: #1b1a3a;

$success: #009b72;
$success-light: #65e09d;
$success-lighter: #73ffb2;
$success-dark: #439467;
$success-darker: #26543b;

$grey: #686266;
$grey-2: #828282;
$grey-light: #ad9ca7;
$grey-lighter: #d8cbd4;
$grey-dark: #756e73;
$grey-darker: #333333;

$black: #212121;
$white: #ffffff;
$light: #ffffe6;

$background: #fcfaf4;

body {
  --primary: #6a2d56;
  --app-background: #6a2d56;
  --bottom-bar: #ffffff;
  --bottom-bar-icon: #6a2d56;
  --bottom-bar-icon-active: #009b72;
  --bottom-bar-text: #6a2d56;
  --bottom-bar-text-active: #009b72;
  --bottom-bar-button: #fff;
  --bottom-bar-button-text: #6a2d56;
  --bottom-bar-button-icon: #6a2d56;
  --bottom-bar-button-active: #009b72;
  --bottom-bar-button-active-text: #ffffff;
  --bottom-bar-button-active-icon: #ffffff;
  --input: #ffffff;
  --input-border: #828282;
  --input-text: #000000;
  --input-placeholder: #757575;
  --input-label: #212121;
  --input-icon: #828282;
  --main-card: #ffffff;
  --main-card-box-shadow: rgb(0 0 0 / 16%) 0px -3px 4px,
    rgb(0 0 0 / 23%) 0px 1px 4px;
  --main-card-text: #333333;
  --main-card-title: #6a2d56;
  --main-card-subtitle: #686266;
  --week-selector-button: #ffffff;
  --week-selector-button-text: #6a2d56;
  --week-selector-button-active: #6a2d56;
  --week-selector-button-text-active: #ffffff;
  --app-header-text: #ffffff;
  --icon: #6a2d56;
  --button-primary: #6a2d56;
  --button-success: #009b72;
  --button-icon: #ffffff;
  --button-primary-color: #ffffff;
  --button-primary-outlined: #6a2d56;
  --button-primary-outlined-color: #6a2d56;
  --logo: #6a2d56;
  --logo-dark-bg: #fff;
  --modal-backdrop: rgba(106, 45, 86, 0.64);
  --modal-background: #ffffff;
  --modal-title: #6a2d56;
  --modal-text: #333333;
  --modal-disclaimer: #686266;
  --success: #009b72;
  --white: #ffffff;
  --danger: #fa5440;
  --warning: #ffb540;
  --info: #0197f6;
  --text: #333333;
  --checklist-text: #333333;
  --checklist-text-checked: #828282;
  --checklist-date: #828282;
  --checklist-date-checked: #828282;
  --checklist-icon: #333333;
  --checklist-icon-checked: #828282;
  --checklist-read-text: #000000;
  --checklist-read-text-checked: #828282;
  --checklist-read-button: #6a2d56;
  --checklist-read-button-checked: #6a2d56;
  --input-box-shadow: none;
  --week-selector-box-shadow: none;
  --button-box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
  --skeleton: #eee;
  --skeleton-light: #f7f7f7;

  --challenge-lp-main: #6a2d56;
  --challenge-lp-main-text: #ffffff;
  --challenge-lp-secondary: #fcfaf4;
  --challenge-lp-secondary-text: #6a2d56;

  --menu: #522242;
  --menu-card: #6a2d56;
  --button-create-plan: #6a2d56;

  --card-box-shadow: 0 0 4px rgb(0 0 0 / 25%);
  --selection-bg: #0197f657;
  --selection-color: #000000;
}

body.dark {
  --app-background: #121212;
  --app-header-text: #d0d0d0;
  --main-card: #2c2c2c;
  --main-card-title: #d0d0d0;
  --main-card-subtitle: #d0d0d0;
  --main-card-text: #d0d0d0;
  --input-label: #d0d0d0;
  --input: #414141;
  --input-text: #d0d0d0;
  --input-border: none;
  --input-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  --input-icon: #d0d0d0;
  --week-selector-button: #383838;
  --week-selector-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25),
    0px 1px 4px rgba(0, 0, 0, 0.25), 0px 1px 4px rgba(0, 0, 0, 0.25),
    0px 1px 4px rgba(0, 0, 0, 0.25);
  --week-selector-button-text: #d0d0d0;
  --week-selector-button-active: #d0d0d0;
  --week-selector-button-text-active: #383838;
  --logo-dark-bg: #d0d0d0;
  --text: #d0d0d0;
  --input-placeholder: #acacac;
  --logo: #d0d0d0;
  --bottom-bar: #383838;
  --bottom-bar-icon: #ababab;
  --bottom-bar-text: #ababab;
  --bottom-bar-icon-active: #009b72;
  --bottom-bar-text-active: #009b72;
  --button-primary: #6a2d56;
  --button-primary-color: #fff;
  --button-primary-outlined: #d0d0d0;
  --button-primary-outlined-color: #d0d0d0;
  --button-success: #009b72;
  --success: #009b72;
  --primary: #6a2d56;
  --bottom-bar-button: #fff;
  --bottom-bar-button-text: #6a2d56;
  --bottom-bar-button-icon: #6a2d56;
  --bottom-bar-button-active: #009b72;
  --bottom-bar-button-active-text: #ffffff;
  --bottom-bar-button-active-icon: #ffffff;
  --checklist-icon: #ababab;
  --checklist-icon-checked: #ababab;
  --checklist-text: #d0d0d0;
  --checklist-text-checked: #828282;
  --checklist-date: #828282;
  --checklist-date-checked: #828282;
  --checklist-read-button: #ababab;
  --checklist-read-button-checked: #ababab;
  --icon: #6a2d56;
  --button-box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
  --modal-backdrop: rgba(0, 0, 0, 0.64);
  --modal-background: #2c2c2c;
  --modal-title: #ababab;
  --modal-text: #ababab;
  --modal-disclaimer: #ababab;
  --skeleton: #ababab;
  --skeleton-light: #d0d0d0;
  --main-card-box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25),
    0px -1px 4px rgba(0, 0, 0, 0.25), 0px -1px 4px rgba(0, 0, 0, 0.25),
    0px -1px 4px rgba(0, 0, 0, 0.25);

  --button-create-plan: #009b72;

  --white: #d0d0d0;
  --danger: #fa5440;
  --warning: #ffb540;
  --info: #0197f6;

  --challenge-lp-main: #121212;
  --challenge-lp-main-text: #d0d0d0;
  --challenge-lp-secondary: #2c2c2c;
  --challenge-lp-secondary-text: #d0d0d0;
  --button-icon: #d0d0d0;

  --menu: #121212;
  --menu-card: #2c2c2c;

  --selection-bg: #6a2d56;
  --selection-color: #fff;
}
