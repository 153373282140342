.number-input {
  .number-input__container {
    display: grid;
    grid-template-columns: 44px 44px 44px;
    gap: 8px 8px;
    grid-template-areas: "control input control";
    font-size: 18px;

    .number-input__control {
      width: 44px;
      height: 39px;
      background: $primary;
      -webkit-appearance: none;

      box-shadow: var(--input-box-shadow);
      border: none;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      cursor: pointer;
    }
    .number-input__input {
      width: 44px;
      height: 39px;
      background: var(--input);
      border: 1px solid var(--input-border);
      box-shadow: var(--input-box-shadow);
      box-sizing: border-box;
      border-radius: 8px;
      text-align: center;
      -webkit-appearance: none;
    }
  }
}
