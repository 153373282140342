.button {
  text-decoration: none;
  border: none;
  box-shadow: var(--button-box-shadow);
  border-radius: 2.5rem;
  text-align: center;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  svg {
    margin-right: 16px;
  }

  svg path {
    stroke: var(--button-icon);
  }

  &.icon-right {
    svg {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &.link {
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    text-decoration: underline;
    color: var(--text);
    padding: 0;
  }

  &.square-rounded {
    border-radius: 8px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 0%;
      height: 50px;
      background-color: $success;
      z-index: -1;
      left: 0;
      transition: all 5s;
      opacity: 1;
      transition-property: height, width, opacity, background-color;
    }

    &.isLoading {
      &::before {
        width: 100%;
      }
    }

    &.fadeOut {
      &::before {
        width: 0%;
        opacity: 0;
        right: 0;
        left: auto;
      }
    }
  }

  &.full-width {
    width: 100%;
  }
}

.button--medium {
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: bold;
  font-family: $font-primary;
  min-width: 166px;
}

.button--default {
  padding: 1rem;
  font-size: 1rem;
  width: 13.875rem;
}

.button--small {
  padding: 0.5rem;
  font-size: 14px;
  width: 45%;
  font-weight: bold;
  max-width: 160px;
}

.button--adapted {
  padding: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  font-family: $font-primary;
}

.button:hover {
  transition: 0.2s;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.button:active {
  transform: scale(0.98);
}

.button--warning {
  @extend .button;
  background-color: $warning;
}

.button[disabled].greyOnDisabled {
  background: $grey;
  color: $white;

  &:hover {
    background-color: $grey;
  }
}

.button--danger {
  @extend .button;
  background-color: $danger;
  color: $white;
}

.button--primary {
  @extend .button;
  background-color: var(--button-primary);
  color: var(--button-primary-color);
}

.button--primary-outlined {
  @extend .button;
  background-color: unset;
  border: 2px solid var(--button-primary-outlined);
  box-shadow: none;
  color: var(--button-primary-outlined);

  svg path {
    stroke: var(--button-primary-outlined);
  }

  &.active {
    background-color: var(--success);
    border: 2px solid var(--success);
    color: var(--white);

    svg path {
      stroke: var(--white);
    }
  }
}

.button--success {
  @extend .button;
  background-color: var(--button-success);
  color: var(--button-primary-color);
}

$buttons: (
  primary: $primary-dark,
  danger: $danger-dark,
  warning: $warning-dark,
  success: $success,
);

@each $button in $buttons {
  .button--#{nth($button,1)}:hover {
    filter: brightness(0.9);
  }
}

.button.no-shadow {
  box-shadow: none;
}

.button--create-plan {
  @extend .button;
  color: var(--button-primary-color);
  background-color: var(--button-create-plan);
}
