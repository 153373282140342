.TimePicker {
  position: relative;

  .TimePicker__inside {
    display: flex;
    justify-content: center;

    .TimePicker__input-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: center;
      width: 115px;
      border: 1px solid $grey;
      border-radius: 8px;
      margin: 16px 0 26px;
      padding: 12px;

      .TimePicker__input {
        display: flex;
        width: 70px;

        .TimePicker__input-hour,
        .TimePicker__input-minute {
          min-width: 50%;
          max-width: 50%;
          width: 50%;
          border: none;
          font-family: $font-secondary;
          font-size: 18px;
          outline: none;
          padding: 0;
          text-align: center;
          -moz-appearance: textfield;
          box-shadow: none;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      .TimePicker__input-icon {
        position: relative;
        overflow: hidden;
        width: 23px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-left: 8px;
        margin-left: 16px;

        svg {
        }
        input {
          padding: 20px;
          border-radius: 20px;
          width: 120px;
          opacity: 1;
          width: 30px;
          padding: 0;
          border: 1px solid;
          border-radius: 0;
          position: absolute;
          top: 0;
          left: -9px;
          height: 30px;
          opacity: 0;
        }
      }
    }
  }
}
