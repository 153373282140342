.app {
  background-color: var(--app-background);
  padding-top: 24px;

  &.ios-native-platform {
    padding-top: env(safe-area-inset-top);
    margin-top: 24px;
  }

  .app__container {
    .app__header {
      max-width: 400px;
      margin: 0 auto;
      padding: 40px 16px 0px 16px;
      animation-name: fade;
      animation-duration: 2s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      color: var(--app-header-text);

      .app__previous {
        .previous__button {
          @include display(flex);
          @include justify-content(center);
          @include align-items(center);
          cursor: pointer;
          outline: none;

          width: 2.4375rem;
          height: 2.4375rem;
          border-radius: 50%;
          background: transparent;
          border: 0.0625rem solid #fff;

          img {
            width: 1.375rem;
            height: 1.375rem;
          }
        }

        .previous__button:active {
          transform: scale(0.98);
        }
      }

      .app__headings {
        padding-bottom: 40px;

        .app__title {
          font-style: normal;
          font-weight: bold;
          line-height: 2.1875rem;
          font-size: 25.63px;
          margin-bottom: $grid-1-mobile / 2;
        }

        .app__subtitle {
          font-style: normal;
          font-weight: normal;
          font-size: $content-normal;
          mix-blend-mode: normal;
        }
      }
    }
  }
}
