// overwriting airbnb date picker style

input._t59y0zw {
  text-align: center;
  cursor: pointer;
  background-color: var(--input) !important;
  color: var(--input-text) !important;
  border: 1px solid var(--input-border) !important;
  text-align: center !important;
  font-size: 13px !important;
  -webkit-appearance: none;
}

div._d1ib85,
div._hcoy2r {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

div._6j0jxpz {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
