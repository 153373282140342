.menu-edit-user {
}

.menu-edit-user__sections {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 3rem;
}

.menu-edit-user__section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.menu-edit-user__section-title {
  font-size: 1rem;
  color: var(--white);
  font-weight: bold;
  font-family: $font-primary;
  text-align: left;
}

.menu-edit-user__section-text {
  text-align: left;
}

.menu-edit-user__bottom {
  text-align: center;
  text-decoration: underline;
}
