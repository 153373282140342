@mixin transition($height, $toggleClass, $time) {
  max-height: 0;
  opacity: 0;
  transition: all $time;
  transition-property: opacity, max-height;
  overflow: hidden;

  &.#{$toggleClass} {
    max-height: $height;
    opacity: 1;
    overflow: visible;
  }
}

.form-plan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;

  .default-form {
    width: 100%;
    margin-bottom: 1.5rem;
    transition: all 0.5s;
    opacity: 1;
    transition-property: max-height, margin-bottom, opacity;

    &.openMoreOptions {
      overflow: hidden;
      opacity: 0;
      margin-bottom: 0;
    }
  }

  .default-button {
    width: 100%;
    transition: all 0.5s;
    opacity: 1;
    transition-property: max-height, opacity;

    &.openMoreOptions {
      opacity: 0;
      overflow: hidden;
    }
  }
  .button {
    width: 100%;
  }

  .form-plan__inputs {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;

    .input-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &.input-wrap:not(:last-child) {
        margin-right: 0;
      }

      &.input-wrap:not(:first-child) {
        margin-left: 0;
      }
    }
  }

  .form__row {
    width: 100%;
    justify-content: space-between;
  }

  .more-options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding-left: 24px;
    font-size: 0.875rem;
    max-height: 1.25rem;
    transition: all 0.5s;
    transition-property: max-height, padding-left, opacity, margin-bottom;
    color: var(--text);

    svg path {
      fill: var(--input-icon);
    }

    &.openMoreOptions {
      padding-left: 1rem;
      margin-bottom: 1.5rem;

      svg {
        transform: rotate(225deg);
        transition: transform 1s;
      }
    }

    &.hidden {
      opacity: 0;
      margin-bottom: 0;
      max-height: 0;
      user-select: none;
      pointer-events: none;
      overflow: hidden;
    }

    svg {
      transform: rotate(0deg);
      margin-right: 1rem;
    }
  }

  .more-options__content {
    opacity: 0;
    transition: all 0.5s;
    transition-property: opacity, max-height;
    overflow: hidden;

    &.openMoreOptions {
      max-height: 26.625rem;
      opacity: 1;
      overflow: visible;
    }

    &.hideDidntFind {
      .didnt-find {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
      }
    }

    .didnt-find {
      padding-left: 1rem;
      font-size: 0.875rem;
      transition: all 0.5s;
      transition-property: opacity, max-height;
      opacity: 1;
      max-height: 22.25rem;
      color: var(--text);

      .didnt-find__title {
        margin-bottom: 0.5rem;
      }

      .form__row {
        margin-bottom: 1rem;
      }

      .didnt-find__subtitle {
        margin-bottom: 1rem;
      }
    }
  }

  &--new-challenge {
    background: var(--main-card);
    max-width: 100%;
    border-radius: 1rem;
    padding: 1.5rem;

    .default-form {
      .week-days-selector {
        margin-bottom: 0;
      }
    }
    .input.jsBiblePickerInput {
      min-width: 6.25rem;
      max-width: 7.6875rem;
      width: 100%;
    }

    .form__row:not(:last-child).more-options:not(.openMoreOptions) {
      margin-bottom: 0;
    }
  }
}

.chapters-per-day,
.quantity-of-days-form {
  padding-top: 1.5rem;

  .small-input {
    padding-left: 24px;
  }
}
