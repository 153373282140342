.emails-box {
  background: var(--input);
  box-shadow: var(--input-box-shadow);
  border-radius: 0.5rem;
  width: 100%;
  color: var(--input-text);

  .emails-box__list {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-wrap: wrap;

    .emails-box__item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      p {
        font-size: 1rem;
      }
      svg {
        margin-top: 0.1875rem;
        cursor: pointer;
        margin-left: 0.5rem;

        path {
          stroke: var(--input-icon);
        }
      }
    }
  }
  .emails-box__input {
    padding: 0 1rem 0 1rem;
    position: relative;
    font-size: 1rem;

    .emails-box__input-suggestion {
      position: absolute;
      left: 0;
      top: 100%;
      background: var(--modal-background);
      left: 1rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
      cursor: pointer;

      padding-left: 1rem;
      width: 17.0625rem;
      padding: 1rem;
      z-index: 1;
      box-shadow: var(--input-box-shadow);
      top: 90%;
    }

    input {
      height: 2.5rem;
      width: 100%;
      border: none;
      background: inherit;
      outline: none;
      font-size: 1rem;
      box-shadow: none;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  &--default {
    border: 0.0625rem solid $black;
    width: 100%;
    border-radius: 0.5rem;
  }
}
