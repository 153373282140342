.article-content {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $font-primary;
    margin-bottom: $grid-1-mobile;
    font-weight: buld;
  }

  h1 {
    font-size: $title-1;
  }

  h2 {
    font-size: $title-2;
  }

  h3 {
    font-size: $title-3;
  }

  h4 {
    font-size: $title-4;
  }

  h5 {
    font-size: $title-5;
  }

  h6 {
    font-size: $title-6;
  }

  p {
    font-size: $content-medium;
    margin-bottom: $grid-1-mobile;
    font-family: $font-secondary;
  }

  ul {
    margin-bottom: 20px;
    counter-reset: item;
    li::before {
      font-family: $font-secondary;
      content: counters(item, ".") " ";
      counter-increment: item;
    }

    li {
      display: block;
      font-family: $font-secondary;
      font-size: 16px;

      p {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        margin-top: 26px;
      }

      ul li::before {
        culor: $black;
        font-weight: normal;
      }

      ul li {
        margin-bottom: 26px;

        ul {
          margin-top: 26px;
        }
      }
    }
  }

  ul[type="a"] {
    counter-reset: chapter;

    li::before {
      content: "(" counter(chapter, lower-latin) ") ";
      counter-increment: chapter;
    }
  }
}
