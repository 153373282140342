.social-button {
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: var(--button-box-shadow);
  border-radius: 8px;
  color: $white;
  margin-bottom: 16px;
  font-size: 16px;
  justify-content: flex-start;
  cursor: pointer;

  svg {
    margin-right: 16px;
  }

  &.facebook {
    background: $info;
  }

  &.google {
    background: $white;
    color: $black;
  }

  &.apple {
    background: $grey-darker;
    color: $white;

    path {
      fill: $white;
    }
  }

  &[disabled] {
    background: $grey;
    color: $white;

    &:hover {
      background-color: $grey;
    }
  }
}
