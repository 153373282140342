.main-card {
  padding: 40px 16px;
  border-radius: 2.5rem;
  max-width: 460px;
  margin: 0 auto;
  animation-name: fadeUp;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: var(--main-card);
  box-shadow: var(--main-card-box-shadow);
  color: var(--main-card-text);
  position: relative;

  .main-card__body {
    max-width: 365px;
    margin: 0 auto;
    width: 100%;

    &.bodyScroll {
      overflow-y: scroll;
      padding-bottom: 24px;
    }
  }

  &.error {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .error__title {
      margin: 60px 0 32px 0;
      padding: 0 8px;
      text-align: center;
      max-width: 320px;
    }

    .error__img {
      border-radius: 2.5rem;
      width: 50%;
    }
  }

  &.no-padding {
    padding: 0;
  }

  .main-card__header {
    .main-card__title {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: normal;
      font-size: 1.375rem;
      line-height: 1.875rem;
      max-width: 296px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;

      color: var(--main-card-title);
    }
  }

  .main-card__footer {
    @include display(flex);
    @include flex-direction(column);
    @include align-items(center);
    padding-top: 48px;

    .main-card__footer-feedback.active {
      display: block;
    }

    .main-card__footer-feedback {
      display: none;
      font-family: $font-secondary;
      text-align: center;
      margin-bottom: $grid-1-mobile;
      color: $danger;
      word-break: break-word;
    }
  }
}
