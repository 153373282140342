.bottom-bar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -1px 4px -1px rgba(0, 0, 0, 0.25);
  background: var(--bottom-bar);
  text-transform: uppercase;
  z-index: 1;
  padding-bottom: env(safe-area-inset-bottom);
  transition: padding 1s;

  .bottom-bar__inside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    padding: 8px 16px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background: var(--bottom-bar);
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      z-index: -1;
      transition: all 0.2s ease-in-out;
      transition-property: top;
    }

    &::before {
      height: 40px;
      width: 70%;
      border-radius: 12px;
      box-shadow: 0px -1px 4px -1px rgba(0, 0, 0, 0.25);
      z-index: -11;
    }

    &::after {
      height: 42px;
      width: 71%;
    }
  }

  &.bottom-bar--challenge {
    .bottom-bar__inside {
      &::before {
        top: -9px;
      }

      &::after {
        top: 0;
      }
    }
  }

  // &.isIphone {
  //   padding-bottom: var(--safe-area-bottom);
  // }

  .bottom-bar__challenge-buttons {
    display: flex;
    justify-content: space-between;
    width: 65%;
    transform: translateY(-4px);
    padding: 0 8px;
    border-radius: 12px;
    position: relative;

    &.isOwner {
      width: 70%;
    }
  }

  .bottom-bar__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--bottom-bar-icon);
    cursor: pointer;
    text-decoration: none;

    svg {
      width: 24px;
    }

    .bottom-bar__icon-svg--stroke-fill {
      path,
      rect {
        fill: var(--bottom-bar-icon);
        stroke: var(--bottom-bar-icon);
      }
    }

    .bottom-bar__icon-svg--stroke {
      path,
      rect {
        stroke: var(--bottom-bar-icon);
      }
    }

    .bottom-bar__icon-svg--fill {
      path,
      rect {
        fill: var(--bottom-bar-icon);
      }
    }

    &.active {
      color: var(--bottom-bar-icon-active);

      .bottom-bar__icon-svg--stroke-fill {
        path,
        rect {
          fill: var(--bottom-bar-icon-active);
          stroke: var(--bottom-bar-icon-active);
        }
      }

      .bottom-bar__icon-svg--stroke {
        path,
        rect {
          stroke: var(--bottom-bar-icon-active);
        }
      }

      .bottom-bar__icon-svg--fill {
        path,
        rect {
          fill: var(--bottom-bar-icon-active);
        }
      }
    }

    &.bottom-bar__icon--menu {
      svg rect {
        transition: all 1s;
      }

      &.active,
      &.inactive {
        svg .hamburger__line-1 {
          animation-name: line1;
          animation-duration: 0.7s;
          animation-iteration-count: 1;
        }

        svg .hamburger__line-2 {
          animation-name: line2;
          animation-duration: 0.5s;
          animation-iteration-count: 1;
        }

        svg .hamburger__line-3 {
          animation-name: line3;
          animation-duration: 0.5s;
          animation-iteration-count: 1;
        }
      }

      &.active {
        color: var(--bottom-bar-icon-active);

        path,
        rect {
          stroke: var(--bottom-bar-icon-active);
          fill: var(--bottom-bar-icon-active);
        }
      }
    }

    .bottom-bar__icon-icon {
      margin-bottom: 4px;
      display: flex;
    }
    .bottom-bar__icon-text {
      font-size: 8px;
      font-weight: bold;
      line-height: 1;
      transition: color 1s;
    }
  }
}
