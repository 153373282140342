.announcement-theme-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 32px 0 40px;

  p {
    padding-bottom: 8px;
  }
}
