.challenges {
  padding: 16px 0 56px;

  .challenges__inside {
  }

  .challenges__title {
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    h1 {
      color: var(--white);
      margin-right: 16px;
      font-family: $font-primary;
      font-weight: bold;
      font-size: 32.4338px;
      line-height: 38px;
      text-align: center;
    }
  }
  .challenges__list {
    margin-bottom: 32px;

    .challenges__list-title {
      h2 {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 22.78px;
        line-height: 27px;
        color: var(--white);
        margin-bottom: 16px;
      }
    }
    .challenges__list-item {
      background: var(--main-card);
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;

      .challenges__list-item-content {
        .challenges__list-item-title {
          font-family: $font-secondary;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: var(--main-card-title);
        }
        .challenges__list-item-subtitle {
          font-family: $font-secondary;
          font-style: normal;
          font-weight: normal;
          font-size: 12.64px;
          line-height: 17px;
          color: var(--main-card-subtitle);
        }
      }
      .challenges__list-item-icon {
        path {
          fill: var(--main-card-title);
        }
      }
    }
  }
}
