.icon {
  background-size: cover;
  display: inline-block;
  height: 0.9375rem;
  width: 0.9375rem;
  margin: 0 .7rem;
}

.icon-download {
  @extend .icon;
  // background-image: url('../img/icon-download.svg');
}

.icon-loading {
  @extend .icon;
  // background-image: url('../img/loading.gif');
}