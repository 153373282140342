.loader {
  display: none;
  
  @include align-items(center);
  @include justify-content(center);
  background-color: $secondary;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  
  .loader__inner {
    @include display(flex);
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    
    .loader__title {
      font-style: normal;
      font-weight: normal;
      font-size: 1.0625rem;
      line-height: 1.4375rem;  
    }
    
    .loader__img {
      width: 5.3869rem;
    }
  }
}

.loader.active {
  @include display(flex);
}