.update-challenge-plan__dates {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .input-wrap {
    text-align: center;

    label {
      color: $black;
      margin-bottom: 8px;
      display: block;
      font-size: 14px;
    }

    > div > div > div {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
}
