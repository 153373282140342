.social-footer {
  width: 100%;
  padding: 24px 0;
  margin: 0 auto;

  .social-footer__inside {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .social-footer__link {
      text-decoration: none;
      display: block;
      padding: 0 24px;

      .social-footer__svg {
      }
    }
  }
}
