.configurations {
  .configurations__item {
    border: 1px solid var(--input-border);
    box-shadow: var(--input-box-shadow);
    background-color: var(--input);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    color: var(--input-text);
    margin-bottom: 8px;
    cursor: pointer;

    svg path {
      fill: var(--input-text);
    }
  }

  .configurations__title {
    margin: 24px 0;
    width: 100%;
    text-align: center;
    font-family: $font-primary;
    font-size: 22.78px;
    color: var(--input-text);
  }
}
