.form {
  @include display(flex);
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);

  .form__row {
    @include display(flex);
    @include flex-direction(row);
  }

  .form__row:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .input-wrap {
    @include display(flex);
    @include flex-direction(column);
    @include align-items(center);
    position: relative;

    &.align-left {
      @include align-items(flex-start);
    }

    .input-wrap__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      color: var(--input-label);

      a {
        color: var(--input-label);
      }
    }
  }

  .input-wrap:not(:last-child) {
    margin-right: 0.625rem;
  }

  .input-wrap:not(:first-child) {
    margin-left: 0.625rem;
  }

  .input-label {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: var(--input-label);
    text-align: left;
  }

  .input-wrap__input {
    position: relative;
    width: 100%;

    .input-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 1rem;

      path {
        stroke: var(--input-icon);
      }
    }
  }

  .input-error {
    font-size: 0.75rem;
    color: $danger;
  }

  .input.with-pointer {
    cursor: pointer;
  }

  .text-area,
  .input {
    width: 7.7381rem;
    border: 0.0625rem solid var(--input-border);
    box-sizing: border-box;
    border-radius: 2.5rem;
    outline: none;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    vertical-align: middle;
    padding: 0.75rem 1rem;
    font-family: $font-tertiary;
    background-color: var(--input);
    color: var(--input-text);
    box-shadow: var(--input-box-shadow);
    -webkit-appearance: none;

    &.full-width {
      width: 100%;
    }

    &.text-left {
      text-align: left;
    }

    &.primary {
      border-radius: 0.5rem;
    }

    &.error {
      border: 0.0625rem solid $danger;
    }
  }

  .text-area {
    height: auto;
  }
}

// fixing aligment missing in DatePicker Airbnb component
.DateInput_input_1rlglx6,
input.DateInput_input_1rlglx6-o_O-DateInput_input__readOnly_bnhx1m,
.DateInput_input_1rlglx6-o_O-DateInput_input__focused_uezegv {
  text-align: center;
  height: 2.9375rem;
  background-color: var(--input) !important;
  border: 1px solid var(--input-border) !important;
  color: var(--input-text) !important;
  box-shadow: var(--input-box-shadow);
  -webkit-appearance: none;
}

div.SingleDatePickerInput_n7937y-o_O-SingleDatePickerInput__withBorder_14l1flk {
  border: none !important;
}

div.DateInput_hcoy2r {
  background: none !important;
}

.DateInput_hcoy2r .DateInput_input_1rlglx6-o_O-DateInput_input__readOnly_bnhx1m,
.DateInput_hcoy2r .DateInput_input_1rlglx6-o_O-DateInput_input__focused_uezegv {
  font-size: 1rem !important;
  padding: 0.5rem !important;
}

.form-feedback {
  font-family: $font-secondary;
  text-align: center;
  margin-bottom: 1.5rem;
  color: $danger;
  word-break: break-word;
}

.small-input {
  .small-input__input-wrap {
    display: flex;
    align-items: center;
    .small-input__input {
      border: 0.0625rem solid $grey-2;
      border-radius: 2.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0.25rem 1rem;
      margin-right: 0.5rem;
      width: 4rem;
      text-align: center;
      font-size: 1rem;
    }
    .small-input__label {
      font-size: 1rem;
      line-height: 1.0625rem;
    }
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
textarea {
  background-color: var(--input);
  border: 1px solid var(--input-border);
  color: var(--input-text);
  -webkit-appearance: none;

  box-shadow: var(--input-box-shadow);
}

label {
  color: var(--input-label);
}

input::placeholder,
textarea::placeholder {
  color: var(--input-placeholder);
  font-size: 14px;
}

.Icon-fill {
  fill: var(--input-icon);
}
