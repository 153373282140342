.bible-picker {
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0 2px 6px, rgba(0, 0, 0, 0.07) 0 0 0 1px;
  z-index: 1;
  background-color: var(--main-card);
  color: var(--input-text);
  padding: 20px 0;
  z-index: 5;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  max-width: 100vw;

  .bible-picker__columns {
    @include display(flex);
    @include flex-direction(row);
    margin-top: 2rem;

    .bible-picker__column:nth-child(1) {
      width: 45%;

      .header__title {
        padding-left: 20px;
      }
    }

    .bible-picker__column:nth-child(2) {
      width: 55%;

      .header__title {
        margin-left: 16px;
      }
    }

    .bible-picker__column {
      height: 80vh;
      display: flex;
      flex-direction: column;

      .column__header {
        .header__title {
          font-family: $font-primary;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.3px;
          padding-bottom: $grid-1-mobile;
        }
      }

      .books,
      .chapters {
        height: 100%;
        overflow-y: scroll;

        .selected {
          color: $primary;
          font-weight: bold;
        }
      }

      .books {
        @include display(flex);
        @include flex-direction(column);

        .book {
          width: 100%;
          display: inline-block;
          text-align: left;
          padding: 14px 5px 14px 20px;
          font-size: 13px;
          cursor: pointer;
        }

        .book:hover {
          background-color: $grey-lighter;
          color: $black;
        }

        .book.active {
          background-color: $grey-lighter;
          color: $primary-dark;
          font-weight: bolder;
          letter-spacing: 0.4px;
        }
      }

      .chapters {
        .chapter {
          width: 40px;
          display: inline-block;
          text-align: center;
          height: 46px;
          padding: 14px;
          font-size: 13px;
          cursor: pointer;
        }

        .chapter.active {
          background-color: $grey-lighter;
          color: $primary-dark;
          font-weight: bolder;
          letter-spacing: 0.4px;
        }
      }
    }
  }

  .bible-picker__header {
    height: 20px;
    width: 100%;
    display: block;

    .bible-picker__close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 9px;
      height: 39px;
      width: 39px;
      @include display(flex);
      @include justify-content(center);
      @include align-items(center);
      cursor: pointer;

      .bible-picker__close-icon {
        width: 13px;

        path {
          fill: var(--input-icon);
        }
      }
    }
  }
}

@media only screen and (min-width: $mobile-small-up) {
  .bible-picker {
    position: absolute;
    width: 320px;
    max-width: 320px;
    bottom: -100px;
    left: -60px;
    top: unset;

    .bible-picker__header {
      .bible-picker__close {
        width: 29px;
        height: 29px;

        .bible-picker__close-icon {
          width: 10px;
        }
      }
    }

    .bible-picker__columns {
      margin-top: 0rem;

      .bible-picker__column {
        height: unset;

        .books,
        .chapters {
          height: 300px;
        }
      }
    }
  }
}
