.week-days-selector {
  padding-left: 24px;
  margin-bottom: 1.5rem;

  .week-days-selector__header {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.5rem;

      path {
        fill: var(--input-icon);
      }
    }
    p {
      color: var(--input-label);
      font-size: 0.875rem;
    }
  }
  .week-days-selector__days {
    display: flex;
    align-items: center;

    .week-days-selector__day {
      border-radius: 50%;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 1rem;
      cursor: pointer;
      color: var(--week-selector-button-text);
      border: 0.0625rem solid var(--week-selector-button-text);
      font-weight: bold;
      font-family: $font-primary;
      background-color: var(--week-selector-button);
      box-shadow: var(--week-selector-box-shadow);

      &.active {
        font-weight: normal;
        color: var(--week-selector-button-text-active);
        background-color: var(--week-selector-button-active);
      }
    }
  }
}
