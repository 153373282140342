.bg-warning {
    background-color: $warning;
}

.bg-primary {
    background-color: $primary;
}

.bg-white {
    background-color: $white;
}

.bg-info {
    background-color: $info;
}