.color-primary {
  color: var(--primary);
}

.color-grey {
  color: $grey;
}
.color-white {
  color: var(--white);
}

.color-black {
  color: $black;
}

.color-info {
  color: var(--info);
}

.color-danger {
  color: var(--danger);
}
