.toggler {
  display: flex;
  padding: 2px;
  border-radius: 50px;
  background: var(--white);
  position: relative;
  width: 48px;
  height: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid $grey;
  transition: background-color 0s ease-in;

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 16px;
    height: 16px;
    margin: auto;
    border-radius: 50%;
    background-color: var(--menu-card);
    transition: all 0.3s ease-in-out;
    transform: translateX(-13px);
    transition-property: transform;
  }

  &.active {
    background: var(--success);
    border: 1px solid $success;

    &::after {
      transform: translateX(13px);
      background-color: var(--white);
    }
  }
}

.toggler-wrap {
  display: flex;
  justify-content: center;

  .p {
    margin-left: 16px;
    cursor: pointer;
  }
}
