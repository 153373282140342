.footer {
  padding: 2rem 0 5rem 0;
  margin-top: auto;

  .footer__text {
    font-style: normal;
    font-weight: normal;
    line-height: 1rem;
    font-size: $content-small;
    text-align: center;
    display: none;
  }

  .footer__logotipo {
    display: flex;
    justify-content: center;
    margin-top: $grid-1-mobile;

    line {
      stroke: var(--logo);
    }

    rect,
    path {
      fill: var(--logo);
    }
  }
}
