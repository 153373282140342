.challenge-lp {
  text-align: center;

  @include desktop {
    text-align: left;
  }

  .challenge-lp__container {
    padding: 0 24px;
    max-width: 1000px;
    margin: 0 auto;

    @include desktop {
      padding: 0;
    }
  }
  .challenge-lp__header {
    background-color: var(--challenge-lp-main);
    padding: 16px 0 32px;

    @include desktop {
      padding: 24px 0 80px;
    }

    .navbar {
      text-align: left;

      .navbar__inside {
        max-width: 1000px;

        @include desktop {
          padding: 0;
        }
      }
    }

    .challenge-lp__header-inside {
      .challenge-lp__header-title,
      .challenge-lp__header-image--mobile,
      .challenge-lp__header-more,
      .challenge-lp__header-image--desktop,
      .challenge-lp__header-text,
      .challenge-lp__header-start-now {
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-duration: 1s;
        animation-name: fadeUp;
      }

      @include desktop {
        display: flex;
        align-items: flex-end;
      }
      .challenge-lp__header-inside--desktop-left {
        .challenge-lp__header-title {
          margin: 40px auto 32px auto;
          font-family: $font-primary;
          font-weight: bold;
          font-size: 32.43px;
          line-height: 38px;
          color: var(--challenge-lp-main-text);
          max-width: 409px;

          @include desktop {
            margin: 80px 0 24px;
            font-size: 36.48px;
            line-height: 43px;
          }
        }

        .challenge-lp__header-image--mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 24px;

          svg {
            max-width: 244px;
          }

          @include desktop {
            display: none;
          }
        }

        .challenge-lp__header-text {
          font-family: $font-secondary;
          font-size: 16px;
          margin: 0 auto 40px auto;
          color: var(--challenge-lp-main-text);
          max-width: 304.95px;

          @include desktop {
            margin: 0 0 48px 0;
          }
        }
        .challenge-lp__header-start-now {
          margin-bottom: 24px;
          display: flex;
          justify-content: center;

          button {
            max-width: 278px;
          }

          @include desktop {
            justify-content: flex-start;

            button {
              margin-bottom: 32px;
            }
            svg {
              width: auto;
            }
          }
        }

        .challenge-lp__header-more {
          font-family: $font-primary;
          font-weight: bold;
          font-size: 32.43px;
          color: var(--challenge-lp-main-text);
        }
      }

      .challenge-lp__header-inside--desktop-left,
      .challenge-lp__header-image--desktop {
        @include desktop {
          width: 50%;

          svg {
            width: 100%;
          }
        }
      }
    }
  }
  .challenge-lp__explanation {
    background-color: var(--challenge-lp-secondary);
    border-radius: 0 40px 0 0;
    padding: 40px 0;
    color: var(--challenge-lp-secondary-text);

    @include desktop {
      padding: 72px 0;
      border-radius: 0 80px 0 0;
    }

    &--2 {
      border-radius: 0;
    }

    .challenge-lp__explanation-inner {
      .challenge-lp__explanation-title {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 25.63px;
        margin-bottom: 32px;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-name: fadeUp;

        @include desktop {
          text-align: center;
          font-size: 32.4338px;
          margin-bottom: 72px;
        }
      }
      .challenge-lp__explanation-items {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        @include desktop {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 32px;
        }

        .challenge-lp__explanation-item:nth-child(1),
        .challenge-lp__explanation-item:nth-child(2),
        .challenge-lp__explanation-item:nth-child(3),
        .challenge-lp__explanation-item:nth-child(4) {
          animation-duration: 1s;
          animation-timing-function: ease;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          animation-name: fadeUp;
        }

        .challenge-lp__explanation-item:nth-child(2) {
          animation-delay: 0.1s;
        }

        .challenge-lp__explanation-item:nth-child(2) {
          animation-delay: 0.4s;
        }

        .challenge-lp__explanation-item:nth-child(3) {
          animation-delay: 0.7s;
        }

        .challenge-lp__explanation-item:nth-child(4) {
          animation-delay: 0.9s;
        }

        .challenge-lp__explanation-item {
          margin-bottom: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          max-width: 278px;
          opacity: 0;
          justify-content: initial;

          @include desktop {
            &.no-row {
              justify-self: center;

              .challenge-lp__explanation-item-description {
                margin-bottom: 32px;
              }
            }

            &:not(.no-row) {
              .challenge-lp__explanation-item-header {
                @include desktop {
                  order: 2;
                }
              }

              margin-bottom: 0;
              // max-width: 450px;
              max-width: none;
              flex-direction: row;

              svg {
                margin-right: 16px;
                min-width: 185px;
                max-width: 185px;
              }
            }
          }

          .challenge-lp__explanation-item-title {
            width: 100%;
            font-family: $font-primary;
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 8px;
          }

          .challenge-lp__explanation-item-description {
            width: 100%;
            font-family: $font-secondary;
            font-size: 16px;
            margin-bottom: 16px;
          }
        }
        .challenge-lp__explanation-item:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.pre-button {
  transition: all 2s;
  max-height: 200px;
  opacity: 1;
  transform: translateY(0px);

  &.showShare {
    transform: translateY(-150px);
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    color: transparent;
  }
}

.challenge-launched {
  padding: 40px 0;
  background: var(--challenge-lp-main);

  @include desktop {
    padding: 72px 0;
  }

  .challenge-launched__container__title {
    font-family: $font-primary;
    font-weight: bold;
    font-size: 32.4338px;
    line-height: 38px;
    text-align: center;
    color: var(--challenge-lp-main-text);
    margin-bottom: 34px;

    @include desktop {
      margin-bottom: 48px;
    }
  }
  .challenge-launched__cta {
    width: 100%;
    display: flex;
    justify-content: center;

    .button {
      max-width: 240px;
    }
  }
}

.see-in-action {
  background-color: var(--challenge-lp-secondary);
  padding: 40px 0;

  @include desktop {
    padding: 24px 40px 72px;
  }

  .see-in-action__title {
    h2 {
      font-family: $font-primary;
      font-weight: bold;
      font-size: 32.4338px;
      line-height: 38px;
      text-align: center;
      color: var(--challenge-lp-secondary-text);
      margin-bottom: 16px;

      @include desktop {
        margin-bottom: 24px;
      }
    }
  }

  .see-in-action__play {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-primary;
    font-weight: bold;
    font-size: 28.8337px;
    line-height: 34px;
    text-align: center;
    color: var(--challenge-lp-secondary-text);
    cursor: pointer;

    svg {
      margin-right: 24px;

      path {
        fill: var(--challenge-lp-secondary-text);
      }
    }
  }
}

.choose-plan {
  background: var(--challenge-lp-main);
  border-radius: 40px 40px 0 0;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include desktop {
    padding: 72px 0;
  }

  .choose-plan__copy {
    text-align: center;
    font-family: $font-secondary;
    font-size: 12.64px;
    line-height: 17px;
    text-align: center;
    color: var(--challenge-lp-main-text);
    margin-top: 32px;

    @include desktop {
      margin-top: 48px;
    }
  }

  .choose-plan__title {
    h2 {
      text-align: center;
      margin-bottom: 32px;
      font-family: $font-primary;
      font-weight: bold;
      font-size: 28.8337px;
      line-height: 34px;
      text-align: center;
      color: var(--challenge-lp-main-text);

      @include desktop {
        margin-bottom: 64px;
      }
    }
  }
  .choose-plan__card {
    border-radius: 40px;
    padding: 32px 24px;
    background-color: var(--modal-background);
    margin: 0 auto;

    @include desktop {
      display: flex;
      flex-direction: row;
      padding: 40px;
      justify-content: center;
      align-items: center;
    }

    .choose-plan__content-item {
      color: var(--challenge-lp-secondary-text);
      text-align: left;

      .choose-plan__content-item-title {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .choose-plan__promo {
          // position: absolute;
          top: -8px;
          left: -8px;
          background: $success-light;
          color: $success-darker;
          padding: 4px 8px;
          margin-bottom: 16px;
        }

        h3 {
          margin-bottom: 16px;
          font-family: $font-primary;
          font-weight: bold;
          font-size: 22.78px;
          line-height: 27px;
          text-align: left;

          @include desktop {
            font-size: 32px;
          }

          span {
            font-size: 16px;
            font-weight: normal;
            font-family: $font-secondary;

            @include desktop {
              font-size: 16px;
            }
          }
        }
      }
      .choose-plan__content-item-description {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 16px;
      }
      .choose-plan__content-item-cta {
        margin-bottom: 16px;
      }
      .choose-plan__content-item-disclaimer {
        color: var(--challenge-lp-secondary-text);
        font-size: 12.64px;
      }
    }
  }
}

.choose-plan__footer {
  display: flex;
  padding-top: 40px;
  font-size: 12px;
  justify-content: space-between;
  max-width: 200px;
  margin: 0 auto;

  a {
    color: var(--challenge-lp-main-text);
  }
}
