.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  transition: all 0.5s;
  pointer-events: none;
  transform: translateY(-20px);
  height: 100%;
  padding: 1.5rem 0;
  color: var(--modal-text);

  &.modalNoPadding {
    .modal__window {
      padding: 0;
    }
  }

  &.fullScreenHeight {
    padding: 0;
  }

  .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--modal-backdrop);
    opacity: 0;
    transition: all 0.5s;
    min-height: 200vh;
    z-index: -1;
  }

  &.modalNoPadding {
    .modal__overlay {
      top: -60px;
      background: var(--modal-backdrop);

      &.fullScreenHeight {
        background: var(--modal-background);
      }
    }
  }

  * {
    pointer-events: none;
  }

  &.enter {
    opacity: 1;
    pointer-events: all;
    transition: all 0.5s;
    transform: translateY(0px);

    .modal__overlay {
      opacity: 1;
    }

    * {
      pointer-events: all;
    }
  }

  &.exit {
    opacity: 0;
    transform: translateY(-20px);

    .modal__overlay {
      opacity: 0;
    }
  }
}

.modal__disclaimer {
  font-size: 0.79rem;
  color: var(--modal-disclaimer);
  margin-top: 1rem;
}

.modal__under-buttons {
  margin-top: 32px;
}

.modal__inside {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal__window--default {
    max-width: 23.125rem;
  }

  .modal__window--medium {
    max-width: 31.25rem;
    padding-top: 4.375rem;
  }

  .modal__window--full-height {
    height: 100%;

    .modal__window-content {
      height: 95%;

      &.fullWindowHeight {
        height: 100%;
      }
    }
  }

  .modal__footer-buttons {
    padding: 0.5rem;
    display: flex;
    justify-content: space-evenly;
  }

  .modal__window {
    background: var(--modal-background);
    padding: 2rem 1.5rem;
    width: 100%;
    border-radius: 2.5rem;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .countdown {
      font-size: 2.25rem;
      display: block;
      margin-top: 0.5rem;
    }

    h3 {
      font-weight: 700;
      font-size: 25px;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    h1,
    h2,
    h3,
    h4 {
      color: var(--modal-title);
    }

    article {
      margin-bottom: 1.5rem;
      color: var(--modal-text);

      p {
        margin-bottom: 0.5rem;
      }
    }
    .modal__footer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      button:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
