.biblical-plan {
  .biblical-plan__columns {
    @include display(flex);
    @include flex-direction(row);
    max-width: 15.625rem;
    margin: 0 auto;
    
    .biblical-plan__column {
      width: 50%;
      
      .biblical-plan__column-title {
        font-weight: bold;
        line-height: 2.3125rem;
      }
      .biblical-plan__column-content {
        font-style: normal;
        font-size: 0.9375rem;
        line-height: 2.3125rem;
      }
    }
  }
}