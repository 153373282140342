.ranking-filter {
  background: var(--input);
  box-shadow: var(--card-box-shadow);
  border-radius: 16px;
  padding: 8px 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin: 0 auto 24px auto;
  text-align: center;
  width: 276px;

  .active {
    color: var(--main-card-title);
  }
  span {
    color: #828282;
  }
}
