.menu {
  background-color: var(--menu);
  color: var(--white);
  padding: 1rem;
  min-height: 100vh;
  padding-bottom: 4.6875rem;

  a {
    color: var(--white);
    text-decoration: none;
  }

  .menu__inside {
    max-width: 365px;
    margin: 0 auto;
  }

  .menu__title {
    font-family: $font-secondary;
    font-size: 1.125rem;
    color: var(--white);
    margin-bottom: 1rem;
  }

  .menu__card {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    background-color: var(--menu-card);
    box-shadow: var(--input-box-shadow);

    .menu__card-title {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      font-family: $font-primary;
      border-bottom: 0.0625rem solid $grey;
      text-transform: uppercase;
      font-weight: bold;

      h2 {
        margin-right: 0.5rem;
        font-size: 0.875rem;
        font-weight: bold;
      }
    }

    .menu__card-item {
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
      }

      &--info {
        background-color: $info-2;
      }
      &--danger {
        background-color: $danger;
        color: $white;
      }
      &--success {
        background-color: $success;
      }
      &--success-rounded,
      &--success-rounded:last-child {
        background-color: $success;
        border-radius: 0.5rem;
      }

      .menu__card-item-left {
        width: 100%;

        &-description,
        &-title {
          font-size: 0.875rem;
          font-family: $font-secondary;
        }

        .menu__card-item-left__item:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        &-title {
          font-weight: bold;
        }
      }
      .menu__card-item-right {
        display: flex;
        height: 100%;
        align-items: center;
      }
    }
  }
}

.menu__user {
  color: var(--white);
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;

  &--modal {
    margin: 0 0 2rem 0;
    color: var(--text);
  }

  .menu__user-pic {
    width: 56px;
    min-width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: var(--white);
    margin-right: 0.5rem;
    background-size: contain;
    border: 2px solid var(--white);
    box-shadow: var(--input-box-shadow);
    background-repeat: no-repeat;
    background-position: center;

    &.no-pic {
      background-size: 29px;
    }
  }

  .menu__user-info {
    margin-left: 16px;
  }

  .menu__user-name {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .menu__user-email {
    font-size: 0.875rem;
  }
}
